<template>
    <div class="details">
        <div
            class="title_box"
            :style="{
                background:
                    'url(' + require('@/assets/bg2.png') + ') no-repeat',
                backgroundSize: 'cover',
            }"
        >
            <div class="name_operation">
                <p>
                    <span>
                        <i class="iconfont icon-wodekehu"></i>
                        {{ companyName }}
                    </span>
                </p>
            </div>
        </div>



        <div class="content_box">
            <div class="left_box">
                <div class="report_box">
                    <Recordings ref="recordings"/>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
import Recordings from './recordings.vue';
export default {
    components: {
        Recordings,
    },
    data() {
        return {
            customerId: "",
            companyName: "",
        };
    },
    created() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            this.companyName = this.$route.query.companyName;
        },
    }
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title_box {
        padding: 16px 20px;
        background: #fff;
        .name_operation {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            p {
                i {
                    color: #2370eb;
                    margin-right: 12px;
                }
                span {
                    font-size: 16px;
                }
            }
            .el-button--primary.is-disabled {
                background-color: #fff;
                border-color: #ebeef5;
                color: #c0c4cc;
            }
            .el-button--primary {
                background-color: #2370eb;
            }
        }
        .relation_content {
            font-size: 14px;
            color: #666;
            padding: 12px 0 14px 0;
            .text {
                color: #333333;
                margin-right: 32px;
            }
        }
        .label {
            font-size: 14px;
        }
    }
    .content_box {
        height: 100%;
        width: 100%;
        flex: 1;
        display: flex;
        margin-top: 16px;
        min-height: 0;
        .left_box {
            height: 100%;
            width: 100%;
            flex: 1;
            min-height: 0;
            display: flex;
            flex-direction: column;
            .tab-box {
                display: flex;
                align-items: center;
                background: #fff;
                height: 44px;
                border-bottom: 1px solid #eee;
                p {
                    cursor: pointer;
                    font-weight: 500;
                    color: #666666;
                    font-size: 14px;
                    padding: 0 16px;
                    height: 100%;
                    line-height: 44px;
                    width: auto;
                }
                .isColor {
                    color: #2370eb;
                    position: relative;
                    span {
                        display: block;
                        width: 16px;
                        height: 3px;
                        background: #2370eb;
                        border-radius: 1px 1px 0px 0px;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        margin-left: -8px;
                    }
                }
            }
            .Information_box {
                flex: 1;
                min-height: 0;
            }
            .trade_box {
                flex: 1;
                min-height: 0;
            }
            .report_box {
                flex: 1;
                min-height: 0;
            }
        }
        .right_box {
            flex: 1;
            margin-left: 16px;
        }
    }
}
.labelList {
    display: inline-block;
    padding: 0 8px;
    height: 24px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    line-height: 24px;
    margin-right: 18px;
    cursor: pointer;
    position: relative;
    .cancel {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -10px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -12px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
}
.blue {
    color: #2370eb;
    border-color: #2370eb;
    .cancel {
        border-left: 10px solid #2370eb;
    }
}
.yellow {
    color: #fa8c15;
    border-color: #fa8c15;
    .cancel {
        border-left: 10px solid #fa8c15;
    }
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    border: 2px solid #2370eb;
    margin-left: 17px;
}
.contact_way_box {
    background-color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 10px;
    position: relative;
}
</style>
