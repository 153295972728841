<template>
    <div class="dealings_box1">
        <div class="dealings_his">
            <div
                class="item"
                v-for="item in data"
                :key="item.id"
            >
                <div>
                    <p>
                        <span>域名</span
                        >{{ item.domain || '- -' }}
                    </p>
                    <p><span>跟进人</span>{{ item.adminName || '- -' }}</p>
                    <p>
                        <span>跟进时间</span>{{ returnFollowDate(item.followTime)}}
                    </p>
                    <p>
                        <span>联系方式</span
                        > {{ item.contactInfo || '- -' }}
                    </p>
                </div>
                <div class="normal">
                    <p>
                        <span>销售进度</span>
                        {{ item.saleProgress || '- -'}}
                    </p>
                    <p>
                        <span>购买产品</span>{{ item.buyProduct || '- -' }}
                    </p>
                    <p>
                        <span>方案</span>{{ item.programme || '- -' }}
                    </p>
                    <p>
                        <span></span>
                    </p>
                </div>
                <div class="con">
                    <span>客户情况</span>
                    <p>{{ item.demand }}</p>
                </div>
                <div class="con">
                    <span>跟进情况</span>
                    <p>{{ item.followDetail }}</p>
                </div>
                <div class="con">
                    <span>下一步计划</span>
                    <p>{{ item.nextPlan }}</p>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { customerRecordList } from '@/api/customer/customer.js';
export default {
    data() {
        return {
            data: [],
            customerId: "",
            companyName: "",
            adminId: "",
            departmentId:""
        };
    },
    created() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            this.customerId = this.$route.query.customerId;
            this.companyName = this.$route.query.companyName;
            if(this.$route.query.adminId){
                this.adminId = this.$route.query.adminId;
            }
            if(this.$route.query.departmentId){
                this.departmentId = this.$route.query.departmentId;
            }
            this.getData();
        },
        getData() {
            let obj = {
                param: {
                    customerId:this.customerId,
                    adminId:this.adminId,
                    departmentId:this.departmentId,
                },
            };
            customerRecordList(obj).then((res) => {
                this.data = res.data;
            });
        },
        returnFollowDate(followTime) {
            console.log(followTime);
            return followTime.slice(0, 10);
        },
    },
};
</script>

<style lang="scss" scoped>
.dealings_box1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 4px;
    .tab_title {
        height: 48px;
        line-height: 48px;
        border-bottom: 1px solid #e5e5e5;
        font-size: 12px;
        padding-left: 8px;
        i {
            color: #2370eb;
            vertical-align: -1px;
        }
        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-right: 16px;
            float: right;
            margin-top: 12px;
        }
    }
    .dealings_his {
        flex: 1;
        overflow-y: auto;
        .item {
            border-bottom: 1px dashed #e5e5e5;
            padding: 16px 24px;
            font-size: 14px;
            > div:first-child {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                position: relative;
                p {
                    color: #333;
                    font-weight: 600;
                }
                .adddesc {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 96px;
                    height: 24px;
                    background: #ffffff;
                    border-radius: 2px;
                    border: 1px solid #f06f1e;
                    color: #f06f1e;
                }
            }
            div {
                span {
                    margin-right: 15px;
                    color: #666;
                    font-weight: normal;
                    white-space: nowrap;
                }
            }
            div + div {
                margin-top: 12px;
            }
            .con {
                display: flex;
                align-items: center;
                p {
                    padding: 6px;
                    font-weight: 600;
                    color: #333;
                    margin-left: -6px;
                    background: #f0f4ff;
                    border-radius: 4px;
                }
            }
            .normal {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                position: relative;
                p {
                    color: #333;
                    font-weight: 600;
                }
                .adddesc {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 96px;
                    height: 24px;
                    background: #ffffff;
                    border-radius: 2px;
                    border: 1px solid #f06f1e;
                    color: #f06f1e;
                }
            }
        }
    }
}
.dialog_info {
    width: 80%;
    margin: 25px auto 150px;
    .info {
        font-size: 14px;
        margin-bottom: 16px;
        text-align: left;
        label {
            display: inline-block;
            width: 65px;
            font-weight: bold;
            color: #333;
            margin-left: 2px;
        }
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
</style>
